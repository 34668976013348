import React from 'react'
import successCheck from 'assets/images/successCheck.svg';
import errorCheck from 'assets/images/error.svg';
import ppMonoWhite from 'assets/images/gray-logo.svg';
import { signerRedirect } from 'utils/helper'
import ButtonComponent from 'components/FormsComponent/ButtonComponent';

const TxnStatusComponent = ({ digest, txnError, onModalClose }: any) => {
    const onTxnDetails = () => {
        window.open(`${signerRedirect}${digest}`, "_blank")
    }
    return (
        <>
            {digest !== "" && (
                <div className='successArea textCenter'>
                    <img src={successCheck} className='mb20' alt="" />
                    <p className='cHeading'>Transaction successful</p>
                    <div>
                        <ButtonComponent extraClass="mb8 w-100 mt10" btnLight onClick={() => onModalClose()}>Close</ButtonComponent>
                        <ButtonComponent extraClass="w-100" btnLight onClick={() => onTxnDetails()}>Transaction details</ButtonComponent>
                    </div>
                </div>
            )}
            {digest == "" && (
                <>
                    {txnError == "" ? (
                        <div className='processingArea textCenter'>
                            <img src={ppMonoWhite} className='mb20' alt="" />
                            <p className='cHeading'>Executing transaction</p>
                            <span className='cText'>Please remain patience while we process your request; this may take a few moments.</span>
                        </div>
                    ) : (
                        <div className='textCenter'>
                            <img src={errorCheck} className='mb20' alt="" />
                            <p className='cHeading'>Error occured</p>
                            <span className='cText'>{txnError}</span>
                            <ButtonComponent extraClass="w-100 mt20" btnLight onClick={() => onModalClose()}>Close</ButtonComponent>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default TxnStatusComponent