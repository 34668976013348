import {
  Connection,
  PublicKey,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
} from "@solana/web3.js";
import { Program, AnchorProvider, BN } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";

const PROGRAM_ID = new PublicKey(
  "EymGJQVWVCB5t6uiUTaE18B5DHaBS59zQBKvEq8vVeTd"
);

const newIDL = {
  version: "0.1.0",
  name: "invesment",
  instructions: [
    {
      name: "receiveSol",
      accounts: [
        { name: "from", isMut: true, isSigner: true },
        { name: "to", isMut: true, isSigner: false },
        { name: "systemProgram", isMut: false, isSigner: false },
      ],
      args: [{ name: "amount", type: "u64" }],
    },
    {
      name: "receiveUsdc",
      accounts: [
        { name: "sender", isMut: true, isSigner: true },
        { name: "receiver", isMut: true, isSigner: false },
        { name: "tokenProgram", isMut: false, isSigner: false },
        { name: "senderTokenAccount", isMut: true, isSigner: false },
      ],
      args: [{ name: "amount", type: "u64" }],
    },
  ],
};

const MAX_RETRIES = 1;
const RETRY_DELAY = 5000; // 5 seconds

const retryTransaction = async (transactionFunc) => {
  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      return await transactionFunc();
    } catch (error) {
      if (attempt === MAX_RETRIES) throw error;
      // console.log(
      //   `Attempt ${attempt} failed. Retrying in ${RETRY_DELAY / 1000
      //   } seconds...`
      // );
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
    }
  }
};

const checkTransactionStatus = async (signature, connection) => {
  try {
    const status = await connection.getSignatureStatus(signature);
    if (status.value && status.value.confirmationStatus === "confirmed") {
      // console.log("Transaction confirmed:", signature);
      return true;
    } else {
      // console.log("Transaction still pending:", signature);
      return false;
    }
  } catch (error) {
    // console.error("Error checking transaction status:", error);
    return false;
  }
};

export const handleTransfer = async (
  amount,
  transferType,
  setMessage,
  wallet,
  setBtnLoading,
  setShowTxnModal,
  callback
) => {
  try {
    setBtnLoading(true);
    const connection = new Connection(
      "https://rpc.ankr.com/solana/c8f645fb306763c1564f37ffa22b2cac81d7e55081aa6aa8abce3931d55d4888",
      "confirmed"
    );
    const provider = {
      connection,
      publicKey: wallet.publicKey,
      signTransaction: async (tx) => {
        return await wallet.signTransaction(tx);
      },
      signAllTransactions: async (txs) => {
        return await wallet.signAllTransactions(txs);
      },

      sendAndConfirm: async (tx, signers) => {
        if (signers?.length) {
          tx.partialSign(...signers);
        }
        const { blockhash } = await connection.getLatestBlockhash();
        tx.recentBlockhash = blockhash;
        tx.feePayer = wallet.publicKey;

        const signedTx = await wallet.signTransaction(tx);
        const rawTransaction = signedTx.serialize();
        setShowTxnModal(true);
        const signature = await connection.sendRawTransaction(rawTransaction);

        let confirmed = false;
        for (let i = 0; i < 10; i++) {
          // Try for about 2 minutes
          confirmed = await checkTransactionStatus(signature, connection);
          if (confirmed) break;
          await new Promise((resolve) => setTimeout(resolve, 12000)); // Wait 12 seconds between checks
        }

        if (!confirmed) {
          throw new Error(
            `Transaction was not confirmed in time. Signature: ${signature}`
          );
        }

        return signature;
      },
    };

    const program = new Program(newIDL, PROGRAM_ID, provider);

    await retryTransaction(async () => {
      if (transferType === "sol") {
        const from = wallet.publicKey;
        const to = new PublicKey(
          "BpciR9tuG3jeyr9axAPGouAs2tTpS8h1KjudzpynJH4Y"
        );
        const lamports = new BN(amount * LAMPORTS_PER_SOL);
        const tx = await program.methods
          .receiveSol(lamports)
          .accounts({
            from: from,
            to: to,
            systemProgram: SystemProgram.programId,
          })
          .transaction();

        const signature = await provider.sendAndConfirm(tx);
        if (signature) {
          callback(signature);
        }
      } else if (transferType === "usdc") {
        const sender = wallet.publicKey;
        const receiver = new PublicKey(
          "BpciR9tuG3jeyr9axAPGouAs2tTpS8h1KjudzpynJH4Y"
        );
        const usdcMint = new PublicKey(
          "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
        );
        const senderAta = await getAssociatedTokenAddress(usdcMint, sender);
        const receiverAta = await getAssociatedTokenAddress(usdcMint, receiver);
        const usdcAmount = new BN(amount * 1000000);
        const tx = await program.methods
          .receiveUsdc(usdcAmount)
          .accounts({
            sender: sender,
            receiver: receiverAta,
            tokenProgram: TOKEN_PROGRAM_ID,
            senderTokenAccount: senderAta,
          })
          .transaction();
        const signature = await provider.sendAndConfirm(tx);
        if (signature) {
          callback(signature);
        }
      } else {
        throw new Error("Invalid transfer type");
      }
    });
  } catch (error) {
    // console.log("error.message", error.message);
    if (error.message.includes("Transaction was not confirmed")) {
      setMessage(
        `Transaction sent but not confirmed. Please check status later. Signature: ${error.message.split(": ")[1]
        }`
      );
      setBtnLoading(false);
      // Here you might want to store the signature and implement a mechanism to check its status later
    } else if (error.message.includes("insufficient funds")) {
      setMessage("Insufficient funds to complete the transaction.");
      setBtnLoading(false);
    } else if (error.message.includes("Transaction simulation failed")) {
      setMessage("Insufficient funds to complete the transaction.");
      setBtnLoading(false);
    } else {
      setMessage(`${error.message}`);
      setBtnLoading(false);
    }
  }
};
