import classNames from 'classnames';
import { useStyles } from "./style";
import "../../assets/common.css";
import pieChart from "assets/images/pieChart02.svg";
import ButtonComponent from 'components/FormsComponent/ButtonComponent';


const InformationComponent = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.mainInformationWrapper)}>
        <div className="heroLabel">
          <p><span>Payments:</span> Contactless on chain transactions</p>
        </div>
        <div className="heroContent">
          <h1>Transforming Commerce</h1>
          <div className='dFlex'>
            <span>PocketPay at the Forefront of Payment Innovation</span>
          </div>
        </div>
        <div className='heroPiechart'>
          <p className='cHeading'>Pre-sale price is $0.11</p>
          <img src={pieChart} alt="pie chart" />
        </div>
      </div>
    </div>
  );
};

export default InformationComponent;